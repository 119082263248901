import { useAllPrismicDocumentsByType } from '@prismicio/react';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import Loading from '../../Infrastructure/Loading';

import { DocumentsDocument } from '../../Vendors/prismic.types.generated';
import DocGroup from './DocGroup';
import checkGroup from './helpers/checkGroup';
import docsByGroup from './helpers/docsByGroup';
import { BASE_URL, SITE_NAME } from '../../constant';

export default function Docs() {
  const [search, setSearch] = useState('');
  const [forms] = useAllPrismicDocumentsByType<DocumentsDocument>('documents', {
    predicates: '[at(my.documents.state, "New York")]',
    orderings: { field: 'my.documents.title' },
  });
  if (forms === undefined) return (<Loading />);
  const entriesByGroup = docsByGroup(forms, search);
  const groupLeft = [
    'Affidavits',
    'Contracts of Sale',
    'Deeds',
    'Information',
    'LLC',
    'Lease',
  ];
  const groupRight = [
    'Miscellaneous',
    "Mortgages & UCC's",
    'Recording Cover Pages',
    'Retainers',
    'Transfer Taxes',
  ];
  checkGroup(Object.keys(entriesByGroup), groupLeft, groupRight);
  const title = 'Forms';
  return (
    <div className="page forms">
      <Helmet>
        <title>{`${title} - ${SITE_NAME}`}</title>
        <meta name="description" content={`Helpful forms: ${groupLeft.join(', ')}, ${groupRight.join(', ')}`} />
        <meta property="og:url" content={`${BASE_URL}/forms`} />
      </Helmet>
      <Container>
        <h1>{title}</h1>
        <Row>
          <Col lg={10}>
            <div className="search">
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                type="text"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={5} md={6}>
            {groupLeft.map((groupName) => (
              <DocGroup
                groupName={groupName}
                entries={entriesByGroup[groupName]}
                key={groupName}
              />
            ))}
          </Col>
          <Col lg={5} md={6}>
            {groupRight.map((groupName) => (
              <DocGroup
                groupName={groupName}
                entries={entriesByGroup[groupName]}
                key={groupName}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
