import React, { useEffect, useState } from 'react';
import { Home2023Document } from '../../Vendors/prismic.types.generated';

export default function Hero({
  home,
}: {
  home: Home2023Document | undefined
}) {
  const [animationClass, setAnimationClass] = useState('slide-in-right');
  const heros: {
    h2: string,
    h1: string,
  }[] = [];
  if (home) {
    home.data.hero_text.forEach((hero) => {
      heros.push({
        h2: hero.title || '',
        h1: hero.title_tagline || '',
      });
    });
  }
  const [heroIndex, setHeroIndex] = useState(0);
  let handles: NodeJS.Timeout[] = [];

  const animateHero = (index: number) => {
    const newIndex = (index + 1) % heros.length;
    const timeoutHandle = setTimeout(() => {
      setAnimationClass('fade-out');
      setTimeout(() => {
        setHeroIndex(newIndex);
        setAnimationClass('slide-in-right');
        animateHero(newIndex);
      }, 1000);
      // handles.push(timeoutHandle2);
    }, 4000);
    handles.push(timeoutHandle);
  };
  // console.log('render hero');
  useEffect(() => {
    if (heros.length > 0 && handles.length === 0) animateHero(0);
    return () => {
      if (handles.length > 0) {
        // console.log('clearing handles');
        handles.forEach((handle) => clearTimeout(handle));
        handles = [];
      }
    };
  }, [heros]);
  return (
    <div className="hero-content">
      <div className="logo">
        <img src="/images/LLA-logo (torch only)-cropped.svg" alt="Liberty Land Abstract" />
      </div>
      {heros.length > 0 && (
      <div className={`copy ${animationClass}`}>
        <h2>{heros[heroIndex].h2}</h2>
        <h1>{heros[heroIndex].h1}</h1>
      </div>
      )}
    </div>
  );
}
