import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';

import Contact from './Pages/Contact/Contact';
import Footer from './Infrastructure/Footer';
import MainNav from './Infrastructure/MainNav';
import Docs from './Pages/Docs/Docs';
import Home from './Pages/Home/Home';
import Location from './Pages/Location';
import Team from './Pages/Team/Team';
import Order from './Pages/Order/Order';
import Prices from './Pages/Prices/Prices';
import Report from './Pages/Report/Report';
import Calculator from './Pages/Calculator';
import Jobs from './Pages/Jobs';

import './Styles/app.scss';
import ThankYou from './Newsletter/ThankYou';
import Courses from './Pages/Courses/Couses';

function App() {
  const location = useLocation();

  ReactGA.initialize('G-1LGTSZWBDT');
  useEffect(() => {
    ReactGA.send('pageview');
  }, [location]);
  return (
    <HelmetProvider>
      <div className="app">
        <MainNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/team/:uid" element={<Team />} />
          <Route path="/team" element={<Team />} />
          <Route path="/forms" element={<Docs />} />
          <Route path="/locations" element={<Location />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/order" element={<Order />} />
          <Route path="/prices" element={<Prices />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/report/:hash" element={<Report />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/courses" element={<Courses />} />
        </Routes>
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default App;
