import { configureStore } from '@reduxjs/toolkit';
import { sliceCalculatorInput } from './sliceCalculatorInput';

const store = configureStore({
  reducer: {
    calculatorInput: sliceCalculatorInput.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
