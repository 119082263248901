import React, { useRef } from 'react';
import Cover from 'react-video-cover';

function VideoBackground({ children, video }: { children: JSX.Element, video: string }) {
  const videoElement = useRef<null | HTMLVideoElement>(null);
  const videoOptions = {
    src: video,
    autoPlay: true,
    muted: true,
    loop: true,
    ref: (videoRef: HTMLVideoElement) => { videoElement.current = videoRef; },
    playsInline: true,
  };
  const toggleMusic = () => {
    if (videoElement?.current) {
      videoElement.current.muted = !videoElement.current.muted;
    }
  };
  return (
    <div className="video-background">
      <div className="video-cover">
        <Cover
          videoOptions={videoOptions}
          remeasureOnWindowResize
        />
      </div>
      <button type="button" onClick={toggleMusic} className="as-text music-toggle">
        <span className={`fas fa-volume-${videoElement?.current?.muted ? 'xmark' : 'high'}`} />
      </button>
      {children}
    </div>
  );
}

export default VideoBackground;
