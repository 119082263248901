import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router';
import * as RichText from '@prismicio/richtext';

import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import { CLOSING_BUY } from '../../RateCalculator/calcConstants';
import { ModelRateInputDataSanitized } from '../../RateCalculator/rateInputData.model';
import { ReportSettingsDocument } from '../../Vendors/prismic.types.generated';
import Loading from '../../Infrastructure/Loading';

export interface IReportGroup {
  heading: string
  rows: IReportRow[]
}
export interface IReportRow {
  title: string
  buyer?: number
  seller?: number
  lender?: number
  notes?: string[]
}

interface RateInputResponse {
  rateInputSanitized?: ModelRateInputDataSanitized
  report: IReportGroup[]
  tridNote: string
  total: {
    seller: number,
    lender: number,
    buyer: number
  }
}

function Report() {
  const { hash } = useParams();

  const [document] = usePrismicDocumentByUID<ReportSettingsDocument>('report_settings', 'preliminary-statement');

  const [reportData, setReportData] = useState<RateInputResponse>({
    report: [],
    tridNote: '',
    total: {
      seller: 0,
      buyer: 0,
      lender: 0,
    },
  });
  useEffect(() => {
    (async () => {
      const results = await axios.get<RateInputResponse>(`${process.env.REACT_APP_SLS_URL}/readRateInput?hash=${hash}`);
      // console.log(results.data);
      setReportData(results.data);
    })();
  }, []);
  const info = reportData.rateInputSanitized;
  const buyerLabel = info?.closingType === CLOSING_BUY ? 'Buyer' : 'Owner';
  if (reportData.report.length === 0) return (<Loading />);
  return (
    <div className="report page">
      <Container>
        <img src={document?.data.logo.url || ''} alt="liberty land logo" className="report-logo" />
        <div className="mini-hero">
          <h1>{RichText.asText(document?.data.title || [])}</h1>
        </div>
        <div className="report-header">
          <PrismicRichText field={document?.data.header} />
        </div>
        <Row>
          <Col lg={{ span: 4, order: 2 }} className="sidebar">
            <table>
              <thead>
                <tr><th colSpan={2}>Title Requst Info</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Purchase</td>
                  <td>{info && info.purchase !== 0 ? `$${info.purchase.toLocaleString()}` : '-'}</td>
                </tr>
                <tr>
                  <td>Loan</td>
                  <td>{info && info.loan !== 0 ? `$${info.loan.toLocaleString()}` : '-'}</td>
                </tr>
                <tr>
                  <td>Transaction</td>
                  <td>
                    {info?.closingType}
                    {info && info.refiType !== '' ? `, ${info.refiType}` : null}
                  </td>
                </tr>
                <tr>
                  <td>County</td>
                  <td>
                    {info?.county}
                    {info?.city ? `, ${info?.city}` : null}
                    {` (Zone ${info?.zone})`}
                  </td>
                </tr>
                <tr>
                  <td>Usage</td>
                  <td>
                    {info?.usageType}
                    {info?.residentialType ? `, ${info?.residentialType}` : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    {buyerLabel}
                    (s)
                  </td>
                  <td>
                    {info?.qtyBuyers}
                  </td>
                </tr>
                {info && info.qtySellers > 0 ? (
                  <tr>
                    <td>Seller(s)</td>
                    <td>
                      {info?.qtySellers}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            <div className="trid-note">{reportData.tridNote}</div>
          </Col>
          <Col>
            <table className={`${reportData.total.lender === 0 ? 'hide-lender ' : ''}${reportData.total.seller === 0 ? 'hide-seller ' : ''}`}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th className="col-buyer">Buyer</th>
                  <th className="col-seller">Seller</th>
                  <th className="col-lender">Lender</th>
                </tr>
              </thead>
              <tbody>
                {reportData.report.map((reportGroup) => (
                  <React.Fragment key={reportGroup.heading}>
                    <tr>
                      <td colSpan={4}><h3>{reportGroup.heading}</h3></td>
                    </tr>
                    {reportGroup.rows.map((row) => (
                      <tr key={row.title}>
                        <td>
                          <div className="description">{row.title}</div>
                          <div className="notes">
                            {row.notes?.map((note) => (
                              <div key={note}>
                                {note}
                              </div>
                            ))}
                          </div>
                        </td>
                        <td className="money col-buyer">{row.buyer ? `$${Math.round(row.buyer).toLocaleString()}` : null}</td>
                        <td className="money col-seller">{row.seller ? `$${Math.round(row.seller).toLocaleString()}` : null}</td>
                        <td className="money col-lender">{row.lender ? `$${Math.round(row.lender).toLocaleString()}` : null}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
                <tr className="total-row">
                  <td>Total</td>
                  <td className="money col-buyer">{reportData.total.buyer !== 0 ? `$${Math.round(reportData.total.buyer).toLocaleString()}` : null}</td>
                  <td className="money col-seller">{reportData.total.seller !== 0 ? `$${Math.round(reportData.total.seller).toLocaleString()}` : null}</td>
                  <td className="money col-lender">{reportData.total.lender !== 0 ? `$${Math.round(reportData.total.lender).toLocaleString()}` : null}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <div className="report-footer">
          <PrismicRichText field={document?.data.footer || []} />
        </div>
      </Container>
    </div>
  );
}

export default Report;
