/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PrismicRichText, PrismicText, usePrismicDocumentByUID } from '@prismicio/react';
import { asText } from '@prismicio/richtext';
import { Helmet } from 'react-helmet-async';
import { ContactUsSettingsDocument } from '../../Vendors/prismic.types.generated';
import Loading from '../../Infrastructure/Loading';
import BasicContactForm from './BasicContactForm';
import { BASE_URL } from '../../constant';

function Contact() {
  const [entry, { state }] = usePrismicDocumentByUID<ContactUsSettingsDocument>('contact_us_settings', 'contact-us');

  if (state !== 'loaded') return (<Loading />);
  if (entry === undefined) return (<Loading />);

  const title = asText(entry.data.contact_us_title);
  const description = asText(entry.data.seo_description);
  const image = `${entry?.data.seo_image.url}&width=1200`;

  return (
    <div className="page contact">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={`${BASE_URL}/contact`} />
      </Helmet>
      <h1>{asText(entry.data.contact_us_title)}</h1>
      <Container>
        <Row className="justify-content-center">
          <Col lg={3} md={4} className="sidebar">
            <div>
              <PrismicText field={entry.data.sidebar_top} />
            </div>
          </Col>
          <Col lg={5} md={8}>
            <BasicContactForm context="Website Contact Us" />
          </Col>
        </Row>
        <Row>
          <Col>
            <section>
              <div className="heading-with-line">
                <h2>{asText(entry.data.title)}</h2>
                <hr />
              </div>
              <Row className="justify-content-center">
                {entry?.data.locations.map((row) => (
                  <Col lg={4} key={row.map_url}>
                    <Row>
                      <Col>
                        <a href={row.map_url || ''}>
                          <img src={row.map.url || ''} alt="map" className="map" />
                        </a>
                      </Col>
                      <Col>
                        <PrismicRichText field={row.address} />
                        <a href={row.map_url || ''}>Get direcrtions</a>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
